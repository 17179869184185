<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Member Registration
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Member Registration</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body" :class="orderClass ? `orderClass` : ``">
                <div class="row mb-1">
                  <div
                    class="d-flex col-12"
                    :class="showForm ? `border-bottom` : ``"
                  >
                    <div
                      class="float-start col-6"
                      v-if="showTable && noEditSearch === true"
                    >
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <div class="float-start col-6" v-show="showForm">
                      <div class="input-group input-group-merge w-50">
                        <h3>{{ modalName }} Member</h3>
                      </div>
                    </div>
                    <div
                      class="float-end col-6"
                      v-if="showTable && noEditSearch === true"
                    >
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="add('member', 0)"
                      >
                        Add
                      </button>
                    </div>
                    <div class="float-end col-6" v-if="showForm">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="
                          [
                            (showTable = true),
                            (showForm = false),
                            (isEdit = false),
                            (editForm = false),
                            (editSearch = false),
                            (noEditSearch = true),
                            (noMember = true),
                            (orderClass = false),
                            (showMember = false),
                            (defaultSuperAdmin = false),
                          ]
                        "
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <section
                  id="ajax-datatable"
                  v-show="showTable"
                  style="order: 2"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="float-start col-6 mb-1" v-show="editSearch">
                          <div class="input-group input-group-merge w-50">
                            <span
                              class="input-group-text"
                              id="basic-addon-search2"
                              ><vue-feather type="search"></vue-feather
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search..."
                              aria-label="Search..."
                              aria-describedby="basic-addon-search2"
                              v-model="searchResult"
                              @keyup="editSearchData"
                            />
                          </div>
                        </div>
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                            v-if="isEdit"
                          >
                            <thead>
                              <tr>
                                <template
                                  v-for="editHead in editHeader"
                                  :key="editHead"
                                >
                                  <th>{{ editHead }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-show="editList.length > 0"
                                v-for="(li, index) in editList"
                                :key="li.id"
                              >
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.first_name }}</td>
                                <td>{{ li.middle_name }}</td>
                                <td>{{ li.last_name }}</td>
                                <td>{{ li.email }}</td>
                                <td>{{ li.mobile }}</td>
                                <td>
                                  <span
                                    class="badge"
                                    :class="
                                      li.is_super_admin
                                        ? `badge-light-success`
                                        : `badge-light-primary`
                                    "
                                    >{{
                                      li.is_super_admin ? "Yes" : "No"
                                    }}</span
                                  >
                                </td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-success rounded"
                                        @click="view(li.id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#large"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="eye"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="
                                          [
                                            edit(form.id, 'member_detail'),
                                            (form.member_detail_id = li.id),
                                          ]
                                        "
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="edit"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="
                                          deleteDes(li.id, 'member_detail')
                                        "
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="trash-2"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="editList.length === 0">
                                <td colspan="7" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template
                                  v-for="editHead in editHeader"
                                  :key="editHead"
                                >
                                  <th>{{ editHead }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <table
                            class="dt-column-search table table-responsive"
                            v-else
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-show="list.length > 0"
                                v-for="(li, index) in list"
                                :key="li.id"
                              >
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.date_of_purchase }}</td>
                                <td>
                                  <span v-if="li.member_detail">
                                  {{
                                    li.member_detail?.first_name +
                                    " " +
                                    li.member_detail?.last_name
                                  }}
                                  </span>
                                </td>
                                <td><span v-if="li.member_detail">{{ li.member_detail.mobile }}</span></td>
                                <td>
                                  <span v-if="li.member_detail">
                                  {{
                                    li.member_detail?.address +
                                    ", " +
                                    li.member_detail?.city +
                                    ", " +
                                    li.member_detail?.state +
                                    ", " +
                                    li.member_detail?.zipcode
                                  }}
                                  </span>
                                </td>
                                <td style="width:100px"> <span v-if="li.villa_master">{{ li.villa_master.number }}</span></td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-success rounded"
                                        @click="
                                          [
                                            add('add_member', li.id),
                                            (form.member_id = li.id),
                                          ]
                                        "
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="plus"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                        <span
                                          class="d-flex align-items-center me-1"
                                          >Add User</span
                                        >
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id, 'member')"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="edit"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDes(li.id, 'member')"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="trash-2"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="8" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex" v-if="!isEdit">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section v-show="showForm" style="order: 1">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="card"
                        :class="editSearch ? `pb-1 border-bottom` : ``"
                      >
                        <div class="card-datatable">
                          <form
                            class="add-new-user pt-0"
                            method="post"
                            @submit.prevent="submitForm()"
                          >
                            <input type="hidden" name="id" :v-modal="form.id" />
                            <div class="flex-grow-1">
                              <div
                                class="border p-1"
                                id="section-block"
                                v-show="showMember"
                              >
                                <div class="row">
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Villa Type <span>*</span></label
                                      >
                                      <Select2
                                        v-model="form.villa_type_id"
                                        :options="villa_types_options"
                                        @change="myChangeEvent($event)"
                                        :settings="{
                                          placeholder: 'Choose Villa Type',
                                        }"
                                        @select="mySelectVillaTypeEvent($event)"
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: villaTypeError }"
                                      >
                                        Please Choose Villa Type.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Villa Number <span>*</span></label
                                      >
                                      <Select2
                                        v-model="form.villa_id"
                                        :disabled="villa_number_disabled"
                                        :options="villa_number_options"
                                        @change="myChangeEvent($event)"
                                        :settings="{
                                          placeholder: 'Choose Villa Number',
                                        }"
                                        @select="mySelectVillaEvent($event)"
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: villaError }"
                                      >
                                        Please Choose Villa Number.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Date of Purchase <span>*</span></label
                                      >
                                      <!-- <input
                                            type="date" class="form-control" name="date_of_purchase" v-model="form.date_of_purchase" id="date_of_purchase" placeholder="Date of Purchase" @change="
                                            form.date_of_purchase.length > 0
                                                ? [(numberError = 'none'), (disabled = false)]
                                                : (numberError = 'block')
                                            "/> -->
                                      <Datepicker
                                        type="date"
                                        name="date_of_purchase"
                                        @closed="purchaseDate()"
                                        v-model="form.date_of_purchase"
                                        calendar-button="false"
                                        format="dd-MM-yyyy"
                                        value-format="dd-MM-yyyy"
                                        modelAuto
                                        multiCalendars
                                        multiCalendarsSolo
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: dateError }"
                                      >
                                        Please select date of purchase.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="border p-1 mt-2"
                                id="section-block"
                                v-show="noMember"
                              >
                                <h4>Member Detail</h4>
                                <input
                                  type="hidden"
                                  name="member_detail_id"
                                  :v-modal="form.member_detail_id"
                                />
                                <div class="row">
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >First Name <span>*</span></label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="first_name"
                                        v-model="form.first_name"
                                        placeholder="First Name"
                                        @keyup="
                                          form.first_name.length > 0
                                            ? [
                                                (firstError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (firstError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: firstError }"
                                      >
                                        Please enter First name.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Middle Name</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="middle_name"
                                        v-model="form.middle_name"
                                        placeholder="Middle Name"
                                        @keyup="
                                          form.middle_name.length > 0
                                            ? [
                                                (middleError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (middleError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: middleError }"
                                      >
                                        Please enter Middle name.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Last Name <span>*</span></label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="last_name"
                                        v-model="form.last_name"
                                        placeholder="Last Name"
                                        @keyup="
                                          form.last_name.length > 0
                                            ? [
                                                (lastError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (lastError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: lastError }"
                                      >
                                        Please enter Last name.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Email</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="email"
                                        v-model="form.email"
                                        placeholder="Email"
                                        
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: emailError }"
                                      >
                                        Please enter Email.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Mobile <span>*</span></label
                                      >
                                      <input
                                        type="number"
                                        class="form-control"
                                        name="mobile"
                                        v-model="form.mobile"
                                        id="mobile"
                                        placeholder="Mobile"
                                        @keyup="
                                          form.mobile.toString().length > 0
                                            ? [
                                                (mobileError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (mobileError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: mobileError }"
                                      >
                                        Please enter Mobile.
                                      </div>
                                    </div>
                                    <div class="row mb-1">
                                      <div class="col-10">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Photo</label
                                        >
                                        <input
                                          type="file"
                                          class="form-control"
                                          name="photo"
                                          ref="file"
                                          placeholder="Photo"
                                          @change="uploadImage"
                                        />
                                        <div
                                          class="invalid-feedback fw-bold fst-italic"
                                          :style="{ display: photoError }"
                                        >
                                          Please Choose Photo.
                                        </div>
                                      </div>
                                      <div class="col-2">
                                        <div id="user-avatar-section">
                                          <div
                                            id="d-flex justify-content-start"
                                          >
                                            <img
                                              v-if="url"
                                              :src="url"
                                              class="img-fluid rounded"
                                              height="104"
                                              width="104"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row mb-1">
                                      <div class="col-10">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Id Proof</label
                                        >
                                        <input
                                          type="file"
                                          class="form-control"
                                          name="id_proof"
                                          ref="proofFile"
                                          placeholder="Id Proof"
                                          @change="uploadProofImage"
                                        />
                                        <div
                                          class="invalid-feedback fw-bold fst-italic"
                                          :style="{ display: proofError }"
                                        >
                                          Please Choose Photo.
                                        </div>
                                      </div>
                                      <div class="col-2">
                                        <div id="user-avatar-section">
                                          <div
                                            id="d-flex justify-content-start"
                                          >
                                            <img
                                              v-if="urlProof"
                                              :src="urlProof"
                                              class="img-fluid rounded"
                                              height="104"
                                              width="104"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="mb-1 mt-1">
                                      <div
                                        class="form-check form-check-inline me-5"
                                      >
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="is_block"
                                          v-model="form.is_block"
                                          :checked="
                                            form.is_block == 1 ? true : false
                                          "
                                        />
                                        <label
                                          class="form-check-label"
                                          for="is_block"
                                          >Is Block</label
                                        >
                                      </div>
                                      <div
                                        class="form-check form-check-inline ml-5"
                                        v-show="defaultSuperAdmin"
                                      >
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="is_super_admin"
                                          v-model="form.is_super_admin"
                                          :checked="defaultSuperAdmin"
                                          :disabled="defaultSuperAdmin"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="is_super_admin"
                                          >Is Super Admin</label
                                        >
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Address <span>*</span></label
                                      >
                                      <textarea
                                        class="form-control"
                                        name="address"
                                        v-model="form.address"
                                        placeholder="Address"
                                        rows="2"
                                        @keyup="
                                          form.address.length > 0
                                            ? [
                                                (addressError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (addressError = 'block')
                                        "
                                      ></textarea>
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: addressError }"
                                      >
                                        Please enter Address.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >City <span>*</span></label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="city"
                                        v-model="form.city"
                                        placeholder="City"
                                        @keyup="
                                          form.city.length > 0
                                            ? [
                                                (cityError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (cityError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: cityError }"
                                      >
                                        Please enter City.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >State <span>*</span></label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="state"
                                        v-model="form.state"
                                        placeholder="State"
                                        @keyup="
                                          form.state.length > 0
                                            ? [
                                                (stateError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (stateError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: stateError }"
                                      >
                                        Please enter State.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Zipcode <span>*</span></label
                                      >
                                      <input
                                        type="number"
                                        class="form-control"
                                        name="zipcode"
                                        v-model="form.zipcode"
                                        id="zipcode"
                                        placeholder="Zipcode"
                                        @keyup="
                                          form.zipcode.toString().length > 0
                                            ? [
                                                (zipcodeError = 'none'),
                                                (this.disabled = false),
                                              ]
                                            : (zipcodeError = 'block')
                                        "
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: zipcodeError }"
                                      >
                                        Please enter Zipcode.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Vehicle No </label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="vehicle_no"
                                        v-model="form.vehicle_no"
                                        id="vehicle_no"
                                        placeholder="Vehicle No"
                                        @change="onChange"
                                        
                                      />
                                      <span>Ex*: GJ 12 AB 3456</span>
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: vehicleNoError }"
                                      >
                                        Please enter Vehicle No.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Vehicle Detail </label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="vehicle_detail"
                                        v-model="form.vehicle_detail"
                                        placeholder="Vehicle Detail"
                                        
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: vehicleDetailError }"
                                      >
                                        Please enter Vehicle Detail.
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Note</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="vehicle_detail"
                                        v-model="form.notes"
                                        placeholder="Notes"
                                        
                                      />
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{ display: notesError }"
                                      >
                                        Please enter Notes.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                type="submit"
                                class="btn btn-primary me-1 data-submit mt-1"
                                :disabled="disabled"
                              >
                                Submit
                              </button>
                              <button
                                v-if="form.member_detail_id !== 0"
                                type="reset"
                                class="btn btn-outline-secondary mt-1"
                                :disabled="disabled"
                                data-bs-dismiss="modal"
                                @click="noMember = false"
                              >
                                Close
                              </button>
                              <button
                                v-else
                                type="reset"
                                class="btn btn-outline-secondary mt-1"
                                :disabled="disabled"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div
      class="modal fade text-start"
      id="large"
      tabindex="-1"
      aria-labelledby="myModalLabel17"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel17">User Detail</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card user-card">
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg"
                  >
                    <div class="user-avatar-section">
                      <div class="d-flex justify-content-start">
                        <img
                          class="img-fluid rounded"
                          :src="this.resProfileUrl + userDetail.photo"
                          height="104"
                          width="104"
                          alt="User avatar"
                        />
                        <div class="d-flex flex-column ms-1">
                          <div class="user-info mb-1">
                            <h4 class="mb-0">
                              {{
                                userDetail.first_name +
                                " " +
                                userDetail.middle_name +
                                " " +
                                userDetail.last_name
                              }}
                            </h4>
                            <a href="mailto: {{ userDetail.email }}"
                              ><span class="card-text">{{
                                userDetail.email
                              }}</span></a
                            ><br />
                            <span class="card-text"
                              >Mo:
                              <a href="tel: {{ userDetail.mobile }}">{{
                                userDetail.mobile
                              }}</a></span
                            >
                          </div>
                          <div class="d-flex flex-wrap">
                            <div
                              class="d-flex align-items-center user-total-numbers"
                              v-if="userDetail.is_super_admin == 1"
                            >
                              <span class="me-75 badge badge-light-success">
                                <span class="d-flex align-items-center me-1"
                                  >Super Admin</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg"
                  >
                    <div class="user-avatar-section">
                      <div class="d-flex justify-content-start">
                        <img
                          class="img-fluid rounded"
                          :src="resProofUrl + userDetail.id_proof"
                          height="104"
                          width="104"
                          alt="User avatar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card user-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
                    <div class="user-info-wrapper">
                      <div class="d-flex flex-wrap">
                        <div class="user-info-title">
                          <i data-feather="user" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Address :
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.address }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="check" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >City :
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.city }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="star" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >State :
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.state }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="flag" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Zipcode :
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.zipcode }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
                    <div class="user-info-wrapper">
                      <div class="d-flex flex-wrap">
                        <div class="user-info-title">
                          <i data-feather="user" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Vehicle No :
                          </span>
                        </div>
                        <p class="card-text mb-0">
                          {{ userDetail.vehicle_no }}
                        </p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="check" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Vehicle Detail :
                          </span>
                        </div>
                        <p class="card-text mb-0">
                          {{ userDetail.vehicle_detail }}
                        </p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="star" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Notes :
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.notes }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="flag" class="me-1"></i>
                          <span class="card-text user-info-title fw-bold mb-0"
                            >Zipcode:
                          </span>
                        </div>
                        <p class="card-text mb-0">{{ userDetail.zipcode }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Select2 from "vue3-select2-component";
import Form from "vform";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Registration",
  components: {
    Pagination: LaravelVuePagination,
    Select2,
    Datepicker,
  },
  data() {
    return {
      form: new Form({
        // Member
        id: null,
        villa_type_id: "",
        villa_id: "",
        date_of_purchase: new Date().toISOString().substr(0, 10),
        // Member Detail
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        photo: "",
        id_proof: "",
        vehicle_no: "",
        vehicle_detail: "",
        notes: "",
        is_block: false,
        is_super_admin: false,
        member: 0,
        member_detail: 0,
        barcode: 0,
        member_id: 0,
        member_detail_id: 0,
        barcode_id: 0,
      }),
      cardNumber: "",
      url: null,
      urlProof: null,
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      villaError: "none",
      villaTypeError: "none",
      dateError: "none",
      firstError: "none",
      middleError: "none",
      lastError: "none",
      emailError: "none",
      numberError: "none",
      mobileError: "none",
      addressError: "none",
      cityError: "none",
      stateError: "none",
      zipcodeError: "none",
      photoError: "none",
      proofError: "none",
      vehicleNoError: "none",
      vehicleDetailError: "none",
      notesError: "none",
      showTable: true,
      showForm: false,
      token: localStorage.getItem("auth_token"),
      showMember: false,
      isEdit: false,
      editHeader: [],
      editList: [],
      noMember: true,
      orderClass: false,
      editSearch: false,
      noEditSearch: true,
      editForm: false,
      defaultSuperAdmin: false,
      resProfileUrl: "",
      resProofUrl: "",
      outcome: {},
      userDetail: {},
      searchResult: null,
      villa_types_options: [],
      villa_number_options: [],
      villa_types_full: [],
      villa_number_disabled: false,
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Date of Purchase",
      "Name",
      "Mobile",
      "Address",
      "Villa",
      "Action",
    ];
    this.editHeader = [
      "Sr. no.",
      "First Name",
      "Middle Name",
      "Last Name",
      "Email",
      "Mobile",
      "Super Admin",
      "Action",
    ];
    // this.$store.dispatch("getVillaTypeList");
    this.getVillaTypes();
    this.getResults();
    var today = new Date().toISOString().split("T")[0];
    document
      .getElementsByName("date_of_purchase")[0]
      .setAttribute("min", today);
    this.defaultSuperAdmin = false;
  },
  methods: {
    async getVillaTypes() {
      this.villa_types_full = [];
      this.villa_types_options = [];
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/villa_type_with_villa_master?page=" +
            0,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((response) => {
          this.villa_types_full = response.data.data;
          if (this.villa_types_full.length) {
            response.data.data.forEach((element) => {
              this.villa_types_options.push({
                id: element.id,
                text: element.text,
              });
            });
          }
        });
    },
    mySelectVillaTypeEvent({ id }) {
      this.form.villa_type_id = id;
      this.disabled = false;
      if (this.form.villa_type_id.length > 0) {
        this.villaTypeError = "none";
      }

      this.villa_number_options = [];
      const villa_list = this.villa_types_full.find((x) => x.id == id);
      if (villa_list) {
        villa_list.villa_list.forEach((element) => {
          if (element.isused_count == 0) {
            this.villa_number_options.push({
              id: element.id,
              text: element.number,
              isused: element.isused_count > 0 ? true : false,
            });
          }
        });
      }
    },
    mySelectVillaEvent({ id }) {
      this.form.villa_id = id;
      this.disabled = false;
      if (this.form.villa_id.length > 0) {
        this.villaError = "none";
      }
    },
    uploadImage(e) {
      let img = e.target.files[0];
      this.form.photo = img;
      this.url = URL.createObjectURL(img);
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.photoError = "none";
        this.disabled = false;
      }
    },
    uploadProofImage(e) {
      let imgProof = e.target.files[0];
      this.form.id_proof = imgProof;
      this.urlProof = URL.createObjectURL(imgProof);
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.proofError = "none";
        this.disabled = false;
      }
    },
    async add(type, id) {
      this.defaultSuperAdmin = true;
      this.showMember = true;
      this.$refs.file.value = "";
      this.$refs.proofFile.value = "";
      this.getVillaTypes();
      (this.form.id = null),
        (this.form.villa_type_id = ""),
        (this.form.villa_id = ""),
        (this.form.date_of_purchase = ""),
        (this.form.first_name = ""),
        (this.form.middle_name = ""),
        (this.form.last_name = ""),
        (this.form.email = ""),
        (this.form.address = ""),
        (this.form.city = ""),
        (this.form.state = ""),
        (this.form.zipcode = ""),
        (this.form.photo = null),
        (this.form.id_proof = ""),
        (this.form.vehicle_no = ""),
        (this.form.vehicle_detail = ""),
        (this.form.notes = ""),
        (this.form.mobile = ""),
        (this.form.is_block = false),
        (this.form.is_super_admin = false),
        (this.response = "");
      this.form.member = 0;
      this.form.member_detail = 0;
      this.form.barcode = 0;
      this.showTable = false;
      this.showForm = true;
      this.disabled = false;
      this.url = null;
      this.urlProof = null;

      this.villaError = "none";
      this.dateError = "none";
      this.firstError = "none";
      this.middleError = "none";
      this.lastError = "none";
      this.emailError = "none";
      this.mobileError = "none";
      this.addressError = "none";
      this.cityError = "none";
      this.stateError = "none";
      this.zipcodeError = "none";
      this.photoError = "none";
      this.proofError = "none";
      this.vehicleNoError = "none";
      this.vehicleDetailError = "none";
      this.notesError = "none";

      if (type === "member") {
        this.showMember = true;
        this.form.id = 0;
        this.modalName = "Add Main";
      }
      if (type === "add_member") {
        this.showMember = false;
        this.form.member_id = id;
        this.modalName = "Add ";
        this.defaultSuperAdmin = false;
      } else {
        this.form.member_id = 0;
      }
    },
    async getResults(page = 1) {

      var searchq = "";
      if (this.search) {
        searchq += "&search=" + this.search;
      }

      await axios
        .get(
          process.env.VUE_APP_API_URL + "/member_registration?page=" + page + searchq,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    purchaseDate() {
      if (this.form.date_of_purchase) {
        this.dateError = "none";
        this.disabled = false;
      } else {
        this.dateError = "block";
      }
    },
    async submitForm() {
      this.disabled = true;
      if (!this.editForm) {
        if (this.form.member_id === 0) {
          this.showMember = true;
          if (this.form.villa_type_id.length === 0) {
            this.villaTypeError = "block";
            return false;
          } else if (this.form.villa_id.length === 0) {
            this.villaError = "block";
            return false;
          } else if (!this.form.date_of_purchase) {
            this.dateError = "block";
            return false;
          }
        }

        if (this.form.first_name.length === 0) {
          this.firstError = "block";
        } else if (this.form.middle_name.length === 0) {
          this.middleError = "block";
        } else if (this.form.last_name.length === 0) {
          this.lastError = "block";
        } 
        // else if (this.form.email.length === 0) {
        //   this.emailError = "block";
        // } 
        else if (this.form.mobile.toString().length === 0) {
          this.mobileError = "block";
        } else if (
          this.form.photo &&
          this.form.photo.length === 0 &&
          this.form.member_detail_id === 0
        ) {
          this.photoError = "block";
        } else if (
          this.form.id_proof &&
          this.form.id_proof.length === 0 &&
          this.form.member_detail_id === 0
        ) {
          this.proofError = "block";
        } else if (this.form.address.length === 0) {
          this.addressError = "block";
        } else if (this.form.city.length === 0) {
          this.cityError = "block";
        } else if (this.form.state.length === 0) {
          this.stateError = "block";
        } else if (this.form.zipcode.toString().length === 0) {
          this.zipcodeError = "block";
        } else {
          this.villaError = "none";
          this.photoError = "none";
          this.proofError = "none";
          if (this.form.member_id !== 0) {
            this.defaultSuperAdmin = false;
          }
          this.form.member = this.form.member_id === 0 ? 1 : 0;
          this.form.member_detail = this.form.member_id !== 0 ? 1 : 0;
          var url = "";
          if (this.form.member_detail_id === 0) {
            url = process.env.VUE_APP_API_URL + "/member_registration";
          } else {
            url =
              process.env.VUE_APP_API_URL +
              "/member_registration/update/" +
              this.form.member_detail_id;
            this.form.member_detail = 1;
            this.form.member = 1;
            this.form.member_id = this.form.member_detail_id;
          }

          this.form.barcode = 0;
          this.form.is_super_admin =
            this.form.member_id === 0 ? true : this.form.is_super_admin;
          console.log(this.form);
          await this.form
            .post(url, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            })
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.disabled = false;
                this.showTable = true;
                this.showForm = false;
                this.getVillaTypes();
                if (this.form.member_detail_id) {
                  this.defaultSuperAdmin = false;
                  this.isEdit = true;
                  this.villaError = "none";
                  this.dateError = "none";
                  this.showForm = true;
                  this.showMember = true;
                  this.disabled = false;
                  this.noMember = true;
                  this.orderClass = true;
                  this.modalName = "Edit";
                  this.editSearch = true;
                  this.noEditSearch = false;
                  this.editForm = false;
                  this.resProfileUrl = "";
                  this.resProofUrl = "";

                  this.form.villa_type_id = "";
                  this.form.villa_id = "";
                  this.form.date_of_purchase = "";
                  this.edit(this.form.id);
                } else {
                  this.getResults();
                }
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
        }
      } else {
        this.form.member = 1;
        await this.form
          .post(
            process.env.VUE_APP_API_URL +
              "/member_registration/update/" +
              this.form.id,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            this.disabled = false;
            // this.showTable = true
            // this.showForm = false
            // this.getResults();
            this.isEdit = true;
            this.villaError = "none";
            this.dateError = "none";
            this.showForm = true;
            this.showMember = true;
            this.disabled = false;
            this.noMember = false;
            this.orderClass = true;
            this.modalName = "Edit";
            this.editSearch = true;
            this.noEditSearch = false;
            this.editForm = true;
            this.getVillaTypes();
            if (response.data.status) {
              this.$toast.success(response.data.message);
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
      }
    },
    deleteDes(id, type) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              process.env.VUE_APP_API_URL + "/member_registration/delete/",
              {
                member: type === "member" ? 1 : 0,
                member_detail: type === "member_detail" ? 1 : 0,
                barcode: 0,
                barcode_id: 1,
                member_detail_id: id,
                member_id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                if (type === "member_detail") {
                  this.disabled = false;
                  this.isEdit = true;
                  this.villaError = "none";
                  this.dateError = "none";
                  this.showForm = true;
                  this.showMember = true;
                  this.disabled = false;
                  this.noMember = false;
                  this.orderClass = true;
                  this.modalName = "Edit";
                  this.editSearch = true;
                  this.noEditSearch = false;
                  this.editForm = true;
                  this.edit(this.form.id);
                } else {
                  this.getResults();
                }
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id, type) {
      this.defaultSuperAdmin = false;
      this.isEdit = true;
      this.villaError = "none";
      this.dateError = "none";
      this.showForm = true;
      this.showMember = true;
      this.disabled = false;
      this.noMember = false;
      this.orderClass = true;
      this.modalName = "Edit";
      this.editSearch = true;
      this.noEditSearch = false;
      this.editForm = true;
      this.resProfileUrl = "";
      this.resProofUrl = "";

      this.form.villa_type_id = "";
      this.form.villa_id = "";
      this.form.date_of_purchase = "";

      const output = await axios.get(
        process.env.VUE_APP_API_URL +
          "/member_registration" +
          (id ? "/" + id : ""),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      var outcome = output.data.data;
      this.form.date_of_purchase = outcome.date_of_purchase;
      this.form.villa_id = outcome.villa_id;
      this.form.villa_type_id = outcome.villa_type_id;

      if (outcome.villa_type_id) {
        this.villa_number_options = [];
        const villa_list = this.villa_types_full.find(
          (x) => x.id == outcome.villa_type_id
        );
        console.log(villa_list);
        if (villa_list) {
          villa_list.villa_list.forEach((element) => {
            if (element.isused_count == 0) {
              this.villa_number_options.push({
                id: element.id,
                text: element.number,
                isused: element.isused_count > 0 ? true : false,
              });
            } else {
              if (element.id == outcome.villa_id) {
                this.villa_number_options.push({
                  id: element.id,
                  text: element.number,
                  isused: element.isused_count > 0 ? true : false,
                });
              }
            }
          });
        }
      }

      this.form.id = outcome.id;
      this.editList = outcome.member_details;
      this.resProfileUrl = outcome.profileUrl;
      this.resProofUrl = outcome.proofUrl;
      this.outcome = { ...outcome, outcome };

      if (type === "member_detail") {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.editForm = false;
        var m_d_id = this.form.member_detail_id;
        var frequencies = this.outcome.member_details;
        var result = frequencies.find(function (e) {
          return e.id == m_d_id;
        });

        result.is_super_admin == 1
          ? (this.defaultSuperAdmin = true)
          : (this.defaultSuperAdmin = false);
        this.noMember = true;
        this.form.first_name = result.first_name;
        this.form.middle_name = result.middle_name;
        this.form.last_name = result.last_name;
        this.form.email = result.email;
        this.form.mobile = result.mobile;
        if (result.photo) {
          this.url = this.resProfileUrl + result.photo;
        }
        if (result.id_proof) {
          this.urlProof = this.resProofUrl + result.id_proof;
        }
        this.form.is_block = result.is_block;
        this.form.is_super_admin = result.is_super_admin;
        this.form.address = result.address;
        this.form.city = result.city;
        this.form.state = result.state;
        this.form.zipcode = result.zipcode;
        this.form.vehicle_no = result.vehicle_no;
        this.form.vehicle_detail = result.vehicle_detail;
        this.form.notes = result.notes;
        this.form.barcode_id = result.barcode_detail.id;
        this.form.barcode = 1;
      }

      this.getVillaTypes();
    },
    async searchData() {
      setTimeout(() => {
        this.getResults();
      }, 300);
    },
    async editSearchData() {
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/member_registration/detail/search",
          {
            search: this.searchResult,
            member_id: this.form.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.editList = response.data.data;
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    view(id) {
      this.userDetail = {};
      var frequencies = this.outcome.member_details;
      var result = frequencies.find(function (e) {
        return e.id == id;
      });
      this.userDetail = { ...result, result };
      console.log(this.userDetail);
    },
  },
  computed: {},
};
</script>
<style>
.orderClass {
  display: flex;
  flex-direction: column;
}
</style>
